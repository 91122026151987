export const AccountRoutes = {
  path: "/u/account",
  component: () => import("@/views/Dashboard/Account/Page"),
  redirect: "/u/account/profile",
  children: [
    {
      path: "/u/account/profile",
      name: "AccountSettings",
      component: () => import("@/views/Dashboard/Account/Profile"),
    },
    {
      path: "/u/account/payment",
      name: "PaymentDetails",
      component: () => import("@/views/Dashboard/Account/PaymentDetails"),
    },
    {
      path: "/u/account/finance",
      name: "FinanceSettings",
      component: () => import("@/views/Dashboard/Account/FinanceSettings"),
    },
    {
      path: "/u/account/password",
      name: "SecuritySettings",
      component: () => import("@/views/Dashboard/Account/AccountPassword"),
    },
  ],
};
