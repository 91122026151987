<template>
  <modal-wrapper
    modalTitle="Consider our pro plan"
    :hasClose="true"
    @closeClicked="confirm(false)"
  >
    <div class="mt-4">
      <div
        class="d-flex align-items-start my-4"
        v-for="(item, index) in proFeatures"
        :key="index"
      >
        <div class="me-2 text__body--x-large text--green opacity-75">
          <i class="bi bi-check-circle-fill"></i>
        </div>
        <div>
          <div class="text__body--large text--medium text--dark">
            {{ item.title }}
          </div>
          <div class="text--dark text__body opacity-75">
            {{ item.description }}
          </div>
        </div>
      </div>
      <div class="mt-5">
        <button
          class="wb-button wb-button--primary w-100 wb-button--no-shadow"
          @click="startSubscription"
        >
          Subscribe for pro <i class="bi bi-gem ms-2"></i>
        </button>
        <div class="my-2"></div>
        <button
          class="wb-button wb-button--cancel w-100 wb-button--no-shadow"
          @click="confirm(false)"
        >
          Continue for free
        </button>
      </div>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from "@/components/Global/ModalWrapper.vue";
import { reactive, ref } from "vue";
export default {
  name: "Subscribe",
  components: {
    ModalWrapper,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const proFeatures = ref([
      {
        title: "Unlock all Pro features",
        description:
          "Unlimited access to all Pro features to create powerful Direct Message automation that saves you time and increases customer engagement.",
      },
      {
        title: "Engage across all channels",
        description:
          "Maximize engagement by leveraging email and SMS channels that are seamlessly built into the Flow Builder.",
      },
      {
        title: "Get more leads",
        description:
          "Attract, qualify, and nurture potential customers with the best tools and without any limits.",
      },
    ]);
    const confirm = (choice) => {
      props.onConfirm(choice);
    };
    const startSubscription = () => {
      console.log("Subscribe!");
    };

    return {
      proFeatures,
      startSubscription,
      confirm,
    };
  },
};
</script>

<style></style>
