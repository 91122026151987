<template>
  <div
    class="auth-side-item d-none d-lg-flex flex-column align-items-start justify-content-between"
    :style="{
      background: `url(${currentSlide.background}) center / cover`,
    }"
  >
    <img
      src="@/assets/brand/logo_white.png"
      class="auth-side-item__brand"
      alt=""
    />
    <div class="w-100 pb-5">
      <div class="auth-side-item__img-wrapper">
        <img
          :class="['auth-side-item__img', { fade: transitioning }]"
          :src="currentSlide.image"
          alt=""
        />
      </div>
      <div class="auth-side-item__content py-2">
        <h1 class="text__title text--white" :class="{ fade: transitioning }">
          {{ currentSlide.title }}
        </h1>
        <p class="text__body text--white" :class="{ fade: transitioning }">
          {{ currentSlide.description }}
        </p>
      </div>
    </div>

    <div class="auth-side-item__indicators">
      <span
        v-for="(slide, index) in slides"
        :key="index"
        :class="['indicator', { active: currentSlideIndex === index }]"
        @click="setSlide(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";

export default {
  name: "SideItem",
  setup() {
    const slides = [
      {
        title: "Take Your Business Online",
        description:
          "Launch your online store effortlessly. With a few simple steps, you can bring your business to the digital world and start reaching more customers.",
        image: require("@/assets/img/auth/intro_1.png"),
        background: require("@/assets/bg/auth_1.png"),
      },
      {
        title: "Manage and Track Orders",
        description:
          "Stay on top of every order with real-time tracking and updates. Manage your customers’ needs easily through a seamless online platform.",
        image: require("@/assets/img/auth/intro_2.png"),
        background: require("@/assets/bg/auth_2.png"),
      },
    ];

    const currentSlideIndex = ref(0);
    const transitioning = ref(false);
    const currentSlide = ref(slides[0]);

    const changeSlide = () => {
      transitioning.value = true;
      setTimeout(() => {
        currentSlideIndex.value = (currentSlideIndex.value + 1) % slides.length;
        transitioning.value = false;
      }, 500);
    };

    const setSlide = (index) => {
      transitioning.value = true;
      setTimeout(() => {
        currentSlideIndex.value = index;
        transitioning.value = false;
      }, 500);
    };

    watchEffect(() => {
      currentSlide.value = slides[currentSlideIndex.value];
    });

    onMounted(() => {
      setInterval(changeSlide, 5000);
    });

    return {
      slides,
      currentSlide,
      currentSlideIndex,
      transitioning,
      setSlide,
    };
  },
};
</script>

<style scoped>
.auth-side-item {
  position: relative;
  height: 100%;
  width: 100%;
  background: #ccc;
  padding: 1rem;
  overflow: hidden;
  border-radius: 1rem;
}
.auth-side-item .auth-side-item__brand {
  max-width: 150px;
}

.auth-side-item .auth-side-item__img-wrapper img {
  width: 100%;
  object-fit: contain;
  transition: opacity 0.5s;
  opacity: 1;
}
.auth-side-item .auth-side-item__content {
  transition: opacity 0.5s;
  opacity: 1;
}
.auth-side-item .auth-side-item__content h1.fade,
.auth-side-item .auth-side-item__img-wrapper img.fade {
  opacity: 0;
  transition: opacity 0.5s;
}

/* Slide Indicators */
.auth-side-item__indicators {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.indicator {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.indicator.active {
  background-color: white;
}
</style>
