import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getStorefrontDelivery({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts/${payload?.storeId}/delivery`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateDelivery({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        `/storefronts/${payload?.storeId}/delivery`,
        {
          ...payload.deliveryInfo,
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
