import { createRouter, createWebHistory } from "vue-router";
import useSubscribe from "@/hooks/useSubscribe";
import store from "@/store";
import { routes } from "./routes";

const showSubscription = useSubscribe();

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.state?.auth?.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresSub = to.matched.some((record) => record.meta.requiresSub);

  if (requiresAuth) {
    if (!isAuthenticated) {
      next({ name: "Login" });
    } else {
      const hasSub = store.state?.auth?.user?.subscribed || false;

      if (requiresSub && !hasSub) {
        next();
        // const isSubscribed = await showSubscription("Consider our pro plan");
        // if (isSubscribed) {
        //   next();
        // } else {
        //   next({
        //     name: "StorefrontList",
        //   });
        // }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
