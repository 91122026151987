import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAllStores({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts?page=${payload?.page || 1}&limit=${
          payload?.limit || 20
        }&search=${payload?.search || ""}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getSingleStore({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(`/storefronts/${payload}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async updateStorefront({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        `/storefronts/${payload.storeId}`,
        payload.data
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async checkStorelinkValidity({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts/link?value=${payload}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async changeStorefrontLink({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(
        `/storefronts/link`,
        payload.data
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewStore({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(`/storefronts`, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
