import axiosInstance from "@/http";
const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getAllStorefrontProducts({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/storefronts/${payload?.id}/products?page=${
          payload?.page || 1
        }&limit=${payload?.limit || 20}&search=${payload?.search || ""}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async addProductsToStore({ commit, state }, payload) {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        `/storefronts/${payload?.storeId}/products`,
        { products: payload?.products }
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async getAllProducts({ commit, state }, payload) {
    try {
      const response = await axiosInstance.get(
        `/products?page=${payload?.page || 1}&limit=${
          payload?.limit || 20
        }&search=${payload?.search || ""}`
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async createNewProduct({ commit, state }, payload) {
    try {
      const response = await axiosInstance.post(`/products`, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
