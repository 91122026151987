import "bootstrap/dist/js/bootstrap.js";
import { numeric, imgLoaded, maxlength } from "@/directives";
import Toast from "vue-toastification";
import InlineError from "@/components/Global/InlineError";
import Spinner from "@/components/Global/Spinner";
import ModalWrapper from "@/components/Global/ModalWrapper";
import EmptyState from "@/components/Global/EmptyState";
import ErrorOccurred from "@/components/Global/ErrorOccurred.vue";
import CreateAttachment from "@/components/Global/CreateAttachment.vue";
import LineLoader from "@/components/Global/LineLoader.vue";
import FixedMessage from "@/components/Global/FixedMessage.vue";
import LoadMoreButton from "@/components/Global/LoadMoreButton.vue";
import DynamicPagination from "@/components/Global/DynamicPagination.vue";
import CurrencySelect from "@/components/Global/CurrencySelect.vue";
import SelectDropdown from "@/components/Global/SelectDropdown.vue";
import VueApexCharts from "vue3-apexcharts";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { CoMoney, IoReceipt, FaUsers, PrInfoCircle } from "oh-vue-icons/icons";

addIcons(CoMoney, IoReceipt, FaUsers, PrInfoCircle);
import { createHead } from "unhead";
const head = createHead();
export default function useImports(app) {
  app.use(VueApexCharts);
  app.directive("numeric", numeric);
  app.directive("img-loaded", imgLoaded);
  app.directive("maxlength", maxlength);
  app.use(head);
  app.use(Toast, {
    timeout: 8000,
    pauseOnFocusLoss: false,
    hideProgressBar: false,
    position: "top-right",
    transition: "Vue-Toastification__fade",
  });
  app.component("v-icon", OhVueIcon);
  app.component("InlineError", InlineError);
  app.component("Spinner", Spinner);
  app.component("ModalWrapper", ModalWrapper);
  app.component("EmptyState", EmptyState);
  app.component("ErrorOccurred", ErrorOccurred);
  app.component("CreateAttachment", CreateAttachment);
  app.component("LineLoader", LineLoader);
  app.component("FixedMessage", FixedMessage);
  app.component("LoadMoreButton", LoadMoreButton);
  app.component("DynamicPagination", DynamicPagination);
  app.component("CurrencySelect", CurrencySelect);
  app.component("SelectDropdown", SelectDropdown);
}
