<template>
  <div class="wb-modal">
    <div
      class="wb-modal__content"
      :class="{
        'wb-modal__content--large': contentLarge,
      }"
    >
      <div
        class="wb-modal__content-controls d-flex align-items-center justify-content-between mb-4"
      >
        <div>
          <div class="text__body text--dark text--medium">
            {{ modalTitle }}
          </div>
          <div
            class="text__body--small text--dark opacity-75"
            v-if="modalDescription"
          >
            {{ modalDescription }}
          </div>
        </div>
        <a
          v-if="hasClose"
          href="javascript:void(0)"
          class="wb-modal__close"
          @click="emitClose"
        >
          <i class="bi bi-x"></i>
        </a>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
  props: {
    hasClose: {
      type: Boolean,
      default: false,
    },
    contentLarge: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    modalDescription: {
      type: String,
      default: "",
    },
  },
  methods: {
    emitClose() {
      this.$emit("closeClicked");
    },
  },
};
</script>

<style scoped>
.wb-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 4rem 1rem;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px -10px rgb(0 0 0 / 20%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  transform: scale(1);
  transition: 0.2s ease-in-out;
}

.wb-modal .wb-modal__content {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 500px;
  background: var(--wb-white);
  z-index: 10;
  border-radius: var(--wb-radius-1);
  padding: 2rem;
}
.wb-modal .wb-modal__content.wb-modal__content--large {
  max-width: 900px;
}
.wb-modal__content-controls {
  position: relative;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--wb-grey1);
}
.wb-modal .wb-modal__close {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--wb-grey4);
  border-radius: 50%;
  color: var(--wb-dark);
  font-size: 1.3rem;
}
</style>
