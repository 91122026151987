import AuthLayout from "@/layouts/Auth/Index.vue";
export const AuthRoutes = {
  path: "/",
  component: AuthLayout,
  children: [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/Auth/Login.vue"),
    },
    {
      path: "/auth/register",
      name: "Register",
      component: () => import("@/views/Auth/Register.vue"),
    },
    {
      path: "/auth/onboarding",
      name: "Onboarding",
      component: () => import("@/views/Auth/Onboarding.vue"),
    },
    {
      path: "/auth/verify_email",
      name: "Verify",
      component: () => import("@/views/Auth/Verify.vue"),
    },
    {
      path: "/auth/forgot_password",
      name: "ForgotPassword",
      component: () => import("@/views/Auth/ForgotPassword.vue"),
    },
    {
      path: "/auth/reset_password",
      name: "ResetPassword",
      component: () => import("@/views/Auth/ResetPassword.vue"),
    },
  ],
};
