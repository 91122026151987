// directives.js
export const numeric = {
  mounted(el) {
    const numericOnlyRegex = /^([0-9]*[.]?[0-9]*)$/;
    el.addEventListener("input", function (event) {
      const currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      const sanitizedValue = numericValue ? numericValue[0] : "";
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
    el.addEventListener("blur", function (event) {
      let currentValue = event.target.value;
      const numericValue = currentValue.match(numericOnlyRegex);
      let sanitizedValue = numericValue ? numericValue[0] : "";
      if (!sanitizedValue.trim()) {
        sanitizedValue = "";
      }
      if (currentValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
        event.target.dispatchEvent(new Event("input"));
      }
    });
  },
};

export const imgLoaded = {
  mounted(el, binding) {
    loadImage(el, binding.value);
  },
  updated(el, binding) {
    loadImage(el, binding.value);
  },
};

function loadImage(el, placeholderUrl) {
  let img = new Image();
  img.src = el.src;
  img.onload = function () {
    el.classList.remove("wb-image-placeholder");
  };
  img.onerror = function () {
    el.src = placeholderUrl;
    el.classList.add("wb-image-placeholder");
  };
}

export const maxlength = {
  mounted(el, binding) {
    let maxLength = binding.value;
    el.addEventListener("input", function (event) {
      if (event.target.value.length > maxLength) {
        event.target.value = event.target.value.slice(0, maxLength);
        event.target.dispatchEvent(new Event("input"));
      }
    });
  },
};
