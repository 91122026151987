import { AccountRoutes } from "./AccountRoutes";
export const Storefront = {
  path: "/u/dashboard",
  name: "Storefront",
  meta: {
    requiresAuth: true,
  },
  component: () => import("@/layouts/Storefront/Index"),
  redirect: "/u/overview",
  children: [
    AccountRoutes,
    {
      path: "/u/storefront/overview",
      name: "Overview",
      component: () => import("@/views/Dashboard/Storefront/Overview"),
    },
    {
      path: "/u/storefront/all",
      component: () => import("@/views/Dashboard/Storefront/Page"),
      children: [
        {
          path: "/u/storefront/all",
          name: "Stores",
          component: () => import("@/views/Dashboard/Storefront/Index"),
        },
        {
          path: "/u/storefront/:storeId/products",
          name: "StorefrontProducts",
          component: () =>
            import("@/views/Dashboard/Storefront/Products/Index"),
        },
        {
          path: "/u/storefront/:storeId",
          name: "StorefrontOverview",
          component: () => import("@/views/Dashboard/Storefront/_id"),
        },
        {
          path: "/u/storefront/:storeId/orders",
          name: "StorefrontOrders",
          component: () =>
            import("@/views/Dashboard/Storefront/Orders/AllOrders"),
        },
        {
          path: "/u/storefront/orders/:orderId",
          name: "SingleStorefrontOrder",
          component: () =>
            import("@/views/Dashboard/Storefront/Orders/SingleOrder"),
        },
        {
          path: "/u/storefront/:storeId/delivery",
          name: "StorefrontDelivery",
          component: () =>
            import("@/views/Dashboard/Storefront/Delivery/Index"),
        },
        {
          path: "/u/storefront/:storeId/customize",
          name: "StorefrontCustomization",
          component: () =>
            import("@/views/Dashboard/Storefront/Customize/Index"),
        },
      ],
    },
    {
      path: "/u/storefront/products",
      component: () => import("@/views/Dashboard/Storefront/Products/Page.vue"),
      children: [
        {
          path: "/u/storefront/products",
          name: "AllProducts",
          component: () =>
            import("@/views/Dashboard/Storefront/Products/AllProducts.vue"),
        },
        {
          path: "/u/storefront/products/categories",
          name: "Categories",
          component: () =>
            import("@/views/Dashboard/Storefront/Products/Categories.vue"),
        },
      ],
    },
    {
      path: "/u/storefront/orders",
      name: "AllOrders",
      component: () =>
        import("@/views/Dashboard/Storefront/Orders/CombinedOrders.vue"),
    },
    {
      path: "/u/storefront/wallet",
      name: "Wallet",
      component: () => import("@/views/Dashboard/Storefront/Wallet/Index.vue"),
    },
    {
      path: "/u/storefront/customers",
      component: () =>
        import("@/views/Dashboard/Storefront/Customers/Page.vue"),
      children: [
        {
          path: "/u/storefront/customers",
          name: "Customers",
          component: () =>
            import("@/views/Dashboard/Storefront/Customers/Index.vue"),
        },
        {
          path: "/u/storefront/customers/:customerId",
          name: "Customer",
          component: () =>
            import("@/views/Dashboard/Storefront/Customers/SingleCustomer.vue"),
        },
      ],
    },
  ],
};
